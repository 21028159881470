import { styled } from "@mui/material/styles";
import MuiLinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";

interface CustomLinearProgressProps extends LinearProgressProps {
  // Add any additional props if needed
}

const LinearProgress = styled(MuiLinearProgress)<CustomLinearProgressProps>(({ theme }) => ({}));

export default LinearProgress;
export * from "@mui/material/LinearProgress";
