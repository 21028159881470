import Box from "../../designSystem/Box";
import LinearProgress from "../../designSystem/LinearProgress";
import Typography from "../../designSystem/Typography";
import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import mainStore from "../../MainStore/store";
import { we_set_user_attributes } from "../../commonUtils";
import { getCookie, store_user_details } from "../../utils/common";
import { REF_HISTORY_COOKIE_KEY } from "../../utils/constants";
import { getPath } from "../../utils/navigation";
import { logoutFn } from "../SignIn/logoutFn";
import { check_device, set_linked_handles } from "./HelperFunctions";

export function finishFBLogin(
  { params: oauth_data, credentials = "", oneTap = false, username, jobId, appId },
  navigate
) {
  let stateId;
  let ref;
  try {
    stateId = localStorage.getItem("facebook_state_id");
    ref = localStorage.getItem("facebook_ref");
  } catch (error) {
    console.log("No localstorage found", error);
  }
  const refCookie = getCookie("pred_ref");
  const urlCookie = getCookie("pref_ref_location");
  const historyCookie = getCookie(REF_HISTORY_COOKIE_KEY);

  let params = {
    username,
    job_id: jobId,
    code: oauth_data != undefined ? oauth_data.code : null,
    state: oauth_data != undefined ? oauth_data.state : null,
    state_id: stateId,
    referral: refCookie,
    user_timezone: mainStore.getState().globalReducer.userTimezone,
    device: check_device(),
    referral_url: urlCookie,
    is_mobile: mainStore.getState().uiStates.isWebView,
    referral_session_history: historyCookie
    // gpt_store_conv_id: session_Id
  };
  if (oneTap) {
    params = {
      credential: credentials,
      referral: refCookie,
      user_timezone: mainStore.getState().globalReducer.userTimezone,
      device: check_device(),
      one_tap: true,
      referral_url: urlCookie,
      is_mobile: mainStore.getState().uiStates.isWebView,
      referral_session_history: historyCookie
      // gpt_store_conv_id: session_Id
    };
  }
  if (appId) {
    params.appId = appId;
  }
  let urlParams = new URLSearchParams(params);
  // set attribute
  we_set_user_attributes({ referral: refCookie, referral_url: urlCookie });
  axios
    .post(`/users/login_facebook/`, urlParams)
    .then((res) => {
      store_user_details(
        res.data["username"],
        res.data["useremail"],
        res.data["user_img_url"],
        res.data,
        "google"
      );
      set_linked_handles(res.data["handle_data"]);
      mainStore.dispatch({
        type: "userData/setLoginPlatform",
        payload: "facebook"
      });
      try {
        localStorage.removeItem("facebook_state_id");
        localStorage.setItem("user_has_posts", res.data["user_has_posts"]);
      } catch (err) {
        console.log(err);
      }
      let navigateTo = "";
      if (res?.data?.next) {
        const url = new URL(res.data.next);
        navigateTo = url.pathname + url.search;
      } else {
        navigateTo = !res.data["user_has_posts"]
          ? "/onboarding/?user_domain=" + res?.data?.user_domain
          : getPath("/dashboard");
      }
      navigate(navigateTo);
    })
    .catch((err) => {
      console.log("error in facebook login - ", err);
      logoutFn(navigate, "/");
    });
}

const FbOauth = () => {
  const { username, jobId } = useSelector((state) => state.globalReducer);
  const navigate = useNavigate();

  useEffect(() => {
    var search_params = new URLSearchParams(window.location.search);
    let all_params = {};
    search_params.forEach(function (v, k) {
      all_params[k] = v;
    });
    if (search_params.get("code")) {
      // localStorage.setItem("google_oauth", JSON.stringify(all_params));
      const params = {
        params: all_params,
        credentials: "",
        oneTap: false,
        username,
        jobId
      };
      if (window.opener) {
        // send them to the opening window
        window.opener.postMessage(params);
        // close the popup
        window.close();
      } else {
        finishFBLogin(params, navigate);
      }
      // window.close();
    } else if (search_params.get("credential")) {
      const params = {
        params: all_params,
        credentials: search_params.get("credential"),
        oneTap: true,
        username,
        jobId
      };
      if (window.opener) {
        // send them to the opening window
        window.opener.postMessage(params);
        // close the popup
        window.close();
      } else {
        finishFBLogin(params, navigate);
      }
    } else {
      console.log("invalid Facebook login");
      logoutFn(navigate, "/");
    }
  }, []);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "200px",
        textAlign: "center"
      }}
    >
      <Typography variant={"body1"}>{t("loginPage.logginFb")}</Typography>
      <LinearProgress variant='indeterminate' />
    </Box>
  );
};

export default FbOauth;
