// import { createSlice } from "@reduxjs/toolkit";
// let initShopifySignup = false;
// let initNeedShopifyDownload = false;
// let initshopifyWsLimitAvail = true;
// let initShopifyUser = false;

// try {
//   initShopifySignup = JSON.parse(localStorage.getItem("shopify_signup"));
//   initNeedShopifyDownload = JSON.parse(localStorage.getItem("need_download_shopify_products"));
//   initshopifyWsLimitAvail = JSON.parse(localStorage.getItem("spf_ws_limit_available"));
//   initShopifyUser = JSON.parse(localStorage.getItem("shopify_user"));
// } catch (error) {}
// const initialState = {
//   ecom_platform: [],
//   ecom_linked: false,
//   shopifySignup: initShopifySignup,
//   needShopifydownload: initNeedShopifyDownload,
//   shopifyWsLimitAvail: initshopifyWsLimitAvail,
//   shopifyUser: initShopifyUser,
//   nOnce: "0",
//   showShopifyNudge: false
// };

// const setShowShopifyNudge = (state, action) => {
//   state.showShopifyNudge = action.payload;
// };
// const setShopifySignup = (state, action) => {
//   state.shopifySignup = action.payload;
// };
// const setNeedShopifyDownload = (state, action) => {
//   state.needShopifydownload = action.payload;
// };
// const setShopifyWsLimitAvail = (state, action) => {
//   state.shopifyWsLimitAvail = action.payload;
// };
// const setNOnce = (state, action) => {
//   state.nOnce = action.payload;
// };
// const setShopifyUser = (state, action) => {
//   state.shopifyUser = action.payload;
// };
// const setEcomPlatform = (state, action) => {
//   state.ecom_platform = action.payload;
// };
// const setEcomLinked = (state, action) => {
//   state.ecom_linked = action.payload;
// };

// const shopifyStateSlice = createSlice({
//   name: "shopifyStates",
//   initialState,
//   reducers: {
//     setShopifySignup,
//     setNeedShopifyDownload,
//     setShopifyWsLimitAvail,
//     setNOnce,
//     setShopifyUser,
//     setShowShopifyNudge,
//     setEcomPlatform,
//     setEcomLinked
//   }
// });

// export default shopifyStateSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
let initEcomSignup = false;
let initNeedEcomDownload = false;
let initEcomWsLimitAvail = true;
let initEcomUser = false;
let initnOnce = "0";

try {
  initEcomSignup = JSON.parse(localStorage.getItem("ecom_signup"));
  initNeedEcomDownload = JSON.parse(localStorage.getItem("need_download_ecom_products"));
  initEcomWsLimitAvail = JSON.parse(localStorage.getItem("ws_limit_available"));
  initEcomUser = JSON.parse(localStorage.getItem("ecom_user"));
  initnOnce = localStorage.getItem("nOnce");
} catch (error) {}
const initialState = {
  ecom_platform: [],
  ecom_linked: false,
  ecomSignup: initEcomSignup,
  needEcomdownload: initNeedEcomDownload,
  wsLimitAvail: initEcomWsLimitAvail,
  ecomUser: initEcomUser,
  nOnce: initnOnce,
  showEcomNudge: false
};

const setShowEcomNudge = (state, action) => {
  state.showEcomNudge = action.payload;
};
const setEcomSignup = (state, action) => {
  state.ecomSignup = action.payload;
};
const setNeedEcomDownload = (state, action) => {
  state.needEcomdownload = action.payload;
};
const setEcomWsLimitAvail = (state, action) => {
  state.wsLimitAvail = action.payload;
};
const setNOnce = (state, action) => {
  state.nOnce = action.payload;
};
const setEcomUser = (state, action) => {
  state.ecomUser = action.payload;
};
const setEcomPlatform = (state, action) => {
  state.ecom_platform = action.payload;
};
const setEcomLinked = (state, action) => {
  state.ecom_linked = action.payload;
};

const ecomStateSlice = createSlice({
  name: "ecomStates",
  initialState,
  reducers: {
    setEcomSignup,
    setNeedEcomDownload,
    setEcomWsLimitAvail,
    setNOnce,
    setEcomUser,
    setShowEcomNudge,
    setEcomPlatform,
    setEcomLinked
  }
});

export default ecomStateSlice.reducer;
