import { createSlice } from "@reduxjs/toolkit";

let initCurrentPostId = "";
let initActiveImageId = "";
let initMediaType = "";
try {
  initCurrentPostId = localStorage.getItem("edit_post_id") || "";
  initActiveImageId = localStorage.getItem("active_img_id") || "";
  initMediaType = localStorage.getItem("edit_media_type") || "";
} catch (error) {}

const initialState = {
  loading: false,
  data: [],
  posts: {
    byId: {},
    allIds: []
  },
  errorMsg: "",
  currentPostId: initCurrentPostId,
  activeImageId: initActiveImageId,
  mediaType: initMediaType,
  currentPostLabel: "",
  activeTemplateId: "",
  editOpen: false,
  previewOpen: false,
  multiDeleteActive: false,
  multipleDeleteIds: [],
  templateIdsArr: [],
  progressCards: 0,
  linkingLimitReached: false,
  voiceoverEnabled: false,
  inProgressPosts: []
};

const updateInProgressPosts = (state, action) => {
  if (action.payload.action === "empty") {
    state.inProgressPosts = [];
  } else if (action.payload.action === "remove") {
    const filteredPosts = state.inProgressPosts.filter((id) => id !== action.payload.id);
    state.inProgressPosts = filteredPosts;
  } else {
    state.inProgressPosts = action.payload.id;
  }
};
const setLinkingLimitReached = (state, action) => {
  state.linkingLimitReached = action.payload;
};
const setCurrentPostLabel = (state, action) => {
  state.currentPostLabel = action.payload;
};
const setPostLoading = (state, action) => {
  state.loading = action.payload;
};
const setPostError = (state, action) => {
  state.loading = false;
  state.errorMsg = action.payload;
};
const setTemplateIdsArr = (state, action) => {
  state.loading = true;
  state.templateIdsArr = action.payload;
};
const setPostData = (state, action) => {
  const postsArr = action.payload;
  state.loading = false;
  if (action.mode === "new" || action.mode === "quick" || action.mode === "filter") {
    state.posts.allIds = [];
    postsArr.length > 0 &&
      postsArr.forEach((post) => {
        state.posts.allIds.push(post?.event_id);
        state.posts.byId[post?.event_id] = post;
      });
  } else if (action.mode === "add_rev") {
    postsArr.length > 0 &&
      postsArr.forEach((post) => {
        state.posts.allIds.unshift(post?.event_id);
        state.posts.byId[post?.event_id] = post;
      });
    // state.data = [...action.payload, ...state.data];
  } else {
    postsArr.forEach((post) => {
      state.posts.allIds.push(post?.event_id);
      state.posts.byId[post?.event_id] = post;
    });
  }
  state.errorMsg = "";
};

const deletePost = (state, action) => {
  if (typeof action.payload === "string") {
    state.posts.allIds = state.posts.allIds.filter((evId) => evId !== action.payload);
    // state.data = state.data.filter((p) => p.event_id !== action.payload);
  } else if (typeof action.payload === "object") {
    state.posts.allIds = state.posts.allIds.filter((evId) => action.payload.indexOf(evId) === -1);
    state.multipleDeleteIds = [];
  }
};
const setActiveTemplateId = (state, action) => {
  state.activeTemplateId = action.payload;
};
const setMediaType = (state, action) => {
  state.mediaType = action.payload;
};
const setCurrentPostId = (state, action) => {
  state.currentPostId = action.payload;
};
const setVoiceoverEnabled = (state, action) => {
  state.voiceoverEnabled = action.payload;
};
const setActiveImageId = (state, action) => {
  state.activeImageId = action.payload;
};
const setEditOpen = (state, action) => {
  state.editOpen = action.payload;
};
const setPreviewOpen = (state, action) => {
  state.previewOpen = action.payload;
};
const setDeleteMultipleActive = (state, action) => {
  state.multiDeleteActive = action.payload;
  // state.multipleDeleteIds = [...state.multipleDeleteIds, action.active_post];
};

const setMultipleDeletePosts = (state, action) => {
  if (action.mode === "add") {
    state.multipleDeleteIds = [...state.multipleDeleteIds, action.payload];
    // state.multipleDeleteIds = [action.payload];
  } else if (action.mode === "reset") {
    state.multipleDeleteIds = [];
  } else {
    state.multipleDeleteIds = state.multipleDeleteIds.filter((p) => p !== action.payload);
  }
};

const toggleArchive = (state, action) => {
  const post_Id = action.payload?.postId;
  const post = state.posts.byId[post_Id];

  if (post) {
    post.archived = !post.archived;
  }
};

const updatePostData = (state, action) => {
  const payload = action.payload;
  const key = payload?.key;
  // let FoundData = state.data.find((p) => p.event_id === payload?.postId);
  let FoundData = state.posts.byId[payload?.postId];
  if (FoundData) {
    FoundData[key] = payload?.data;
  }
};

const updateCreativeDetails = (state, action) => {
  if (action.mode === "carousel") {
    // Find the post from event id, then navigate to pages and map over them changing the url to the corresponding array's index from payload
    // let creativeDetails = state.data.find((el) => el.event_id === action.event_id);
    let creativeDetails = state.posts.byId[action.event_id];
    if (creativeDetails?.creative_details?.length > 0) {
      const activeIndex = creativeDetails["creative_details"].findIndex((t) => t.is_active);
      if (
        action?.payload.length === creativeDetails["creative_details"][activeIndex].pages.length
      ) {
        creativeDetails["creative_details"][activeIndex].pages.map((el, i) => {
          return (el.url = action.payload[i]);
        });
      } else {
        creativeDetails["creative_details"][activeIndex].pages.length = action.payload.length;
        const pages = creativeDetails["creative_details"][activeIndex].pages;
        action.payload?.map((url, i) => {
          pages[i]?.url ? (pages[i]["url"] = url) : (pages[i] = { url });
        });
      }
    }
  } else if (action.mode === "video") {
    let creativeDetails = state.posts.byId[action.event_id];

    if (creativeDetails?.creative_details?.length > 0) {
      const activeIndex = creativeDetails["creative_details"].findIndex((t) => t.is_active);
      creativeDetails["creative_details"][activeIndex].thumb_url = action.thumb_url;
      creativeDetails["creative_details"][activeIndex].url = action.payload;
    }
  } else {
    let creativeDetails = state.posts.byId[action.event_id];
    if (creativeDetails?.creative_details.length > 0) {
      const activeIndex = creativeDetails["creative_details"].findIndex((t) => t.is_active);
      creativeDetails["creative_details"][activeIndex].url = action.payload;
    }
  }
};
const resetTempId = (state) => {
  state.templateIdsArr = [];
};
const increaseProgressCards = (state) => {
  state.progressCards += 1;
};
const decreaseProgressCards = (state) => {
  state.progressCards -= 1;
};
const getPostsSlice = createSlice({
  name: "getPosts",
  initialState,
  reducers: {
    setCurrentPostLabel,
    setPostLoading,
    setLinkingLimitReached,
    setPostError,
    setActiveTemplateId,
    setPostData,
    setCurrentPostId,
    setActiveImageId,
    setMediaType,
    setEditOpen,
    setPreviewOpen,
    deletePost,
    setDeleteMultipleActive,
    setMultipleDeletePosts,
    updatePostData,
    setTemplateIdsArr,
    resetTempId,
    increaseProgressCards,
    decreaseProgressCards,
    updateCreativeDetails,
    setVoiceoverEnabled,
    toggleArchive,
    updateInProgressPosts
  }
});

export default getPostsSlice.reducer;
