import axios from "axios";
import mainStore from "../../MainStore/store";
import { we_login, we_set_user_attributes } from "../../commonUtils";
import { getCookie, store_user_details } from "../../utils/common";
import {
  LOGIN_PLATFORM_SEMRUSH,
  REF_HISTORY_COOKIE_KEY,
  SEMRUSH_HOST
} from "../../utils/constants";
import { localStorageSafe } from "../../utils/localStorageUtils";
import { getPath } from "../../utils/navigation";
import { check_device, runValidations, set_linked_handles } from "../SignUp/HelperFunctions";

export const loginFn = (emailVal, passVal, app_id) => {
  let validationsComplete = runValidations(emailVal, passVal);
  const { isWebView } = mainStore.getState().uiStates;
  return new Promise((resolve, reject) => {
    if (validationsComplete) {
      const search_params = new URLSearchParams(window.location.search);
      const refCookie = getCookie("pred_ref");
      const locationCookie = getCookie("pref_ref_location");
      const historyCookie = getCookie(REF_HISTORY_COOKIE_KEY);
      const sessionId = search_params.get("conv_id");
      var params = {
        mode: "check",
        device: check_device(),
        user_timezone: mainStore.getState().globalReducer.userTimezone,
        referral: refCookie,
        app_id: app_id,
        referral_url: locationCookie,
        referral_session_history: historyCookie,
        is_mobile: isWebView,
        ...(sessionId && sessionId !== "null" && { conv_id: sessionId })
        // gpt_store_conv_id: session_Id //check if this is required
      };
      let sendData = new URLSearchParams(params);
      axios
        .post("/users/authorize_login/", sendData, {
          headers: {
            Authorization: "Basic " + btoa(emailVal + ":" + passVal)
          }
        })
        .then((res) => {
          if (res.data.is_valid) {
            store_user_details(
              res.data["username"],
              res.data["useremail"],
              res.data["user_img_url"],
              // res.data["system_uid"],
              res.data,
              "username_password"
            );
            // set_linked_handles(res.data["handle_data"]);
            mainStore.dispatch({
              type: "userData/setLoginPlatform",
              payload: "username_password"
            });
            try {
              localStorage.setItem("user_has_posts", res.data["user_has_posts"]);
            } catch (error) {}
            let navigateTo = "";
            if (res?.data?.next) {
              const url = new URL(res.data.next);
              navigateTo = url.pathname + url.search;
            } else {
              //TODO : check condition for E
              navigateTo = !res.data["user_has_posts"]
                ? "/onboarding/?user_domain=" + res.data["user_domain"]
                : getPath("/dashboard");
            }

            // set attribute
            we_set_user_attributes({
              referral: refCookie,
              referral_url: locationCookie
            });
            resolve(navigateTo);
          } else {
            reject(res.data.error_msg);
          }
        })
        .catch((err) => reject(err));
    } else {
      reject("Please Fix Validation Errors");
    }
  });
};

export const autoLoginFn = () => {
  mainStore.dispatch({
    type: "uiStates/showAlert",
    alertType: "success",
    payload: "Logging in..."
  });
  return new Promise((resolve) => {
    setTimeout(() => {
      try {
        // if user was autologged in and has not completed onboarding initially
        const user_has_posts = localStorage.getItem("user_has_posts")
          ? localStorage.getItem("user_has_posts")
          : "true";
        const onboarding_version = localStorage.getItem("onboarding_version");
        mainStore.dispatch({
          type: "onboarding/setType",
          payload: onboarding_version
        });
        // let redirectTo = !user_has_posts === "true" ? "/dashboard" : "/content_library"; //redirect to new onboarding
        let redirectTo = user_has_posts === "false" ? "/onboarding" : getPath("/dashboard"); //redirect to new onboarding
        try {
          localStorage.getItem("fbse_uid") && we_login(localStorage.getItem("fbse_uid"));
        } catch (er) {
          console.log(er.message);
        }

        resolve(redirectTo);
      } catch (error) {
        resolve("/");
      }
    }, 1000);
  });
};

export const verifyEmailFn = () => {
  let search_paramas = new URLSearchParams(window.location.search);
  let email_token = search_paramas.get("email_token");
  let useremail = search_paramas.get("username");
  useremail = useremail.replace(/ /g, "+");
  var params = {
    username: useremail,
    email_token: email_token,
    platform: "username_password",
    device: check_device(),
    user_timezone: mainStore.getState().globalReducer.userTimezone,
    url_params: window.location.search,
    // referral: getCookie("pred_ref"),
    user_referral_code: search_paramas.get("user_referral_code")
  };
  if (search_paramas.get("session_id")) {
    params = {
      ...params,
      ...{ session_id: search_paramas.get("session_id") }
    };
  }
  let sendData = new URLSearchParams(params);
  mainStore.dispatch({
    type: "uiStates/showAlert",
    alertType: "success",
    payload: "Logging in..."
  });
  try {
    window.fbq("track", "CompleteRegistration"); // complete registration event for facebook pixel
  } catch (error) {
    console.log("error fbq completeRegistration - ", error);
  }
  return new Promise((resolve, reject) => {
    axios
      .post("/users/confirm_signup_email/", sendData)
      .then((res) => {
        let msg_data = res.data;
        if (msg_data["is_valid"]) {
          store_user_details(
            msg_data["username"],
            msg_data["useremail"],
            msg_data["user_img_url"],
            msg_data,
            "username_password"
          );
          // set attribute
          we_set_user_attributes({
            referral: getCookie("pred_ref"),
            referral_url: getCookie("pref_ref_location")
          });

          set_linked_handles(msg_data["handle_data"]);
          try {
            localStorage.setItem("user_has_posts", msg_data["user_has_posts"]);
          } catch (error) {}
          let redirectTo = "";
          if (res?.data?.next) {
            const url = new URL(res.data.next);
            redirectTo = url.pathname + url.search;
          } else {
            redirectTo = !msg_data["user_has_posts"]
              ? "/onboarding/?user_domain=" + msg_data["user_domain"]
              : getPath("/dashboard");
          }

          resolve(redirectTo);
        } else {
          reject(msg_data["error_msg"]);
        }
      })
      .catch((err) => reject(err.message));
  });
};

export function check_inFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
export const loginSemrush = (jwt) => {
  const currentURL = new URL(window.location.href);
  try {
    localStorage.setItem("jwt", jwt);
  } catch (error) {
    console.log("No localstorage found loginsemrush", error);
  }
  let params = new URLSearchParams({
    device: check_device(),
    user_timezone: mainStore.getState().globalReducer.userTimezone
  });
  return new Promise((resolve, reject) => {
    axios
      .post("/users/login_semrush/", params, {
        headers: { Authorization: `Bearer ${jwt}` }
      })
      .then((res) => {
        let msg_data = res.data;
        if (msg_data.is_valid) {
          if (currentURL.host === SEMRUSH_HOST) {
            store_user_details(
              msg_data["username"],
              msg_data["useremail"],
              msg_data["user_img_url"] || "",
              // msg_data["system_uid"],
              msg_data,
              LOGIN_PLATFORM_SEMRUSH
            );
            try {
              localStorage.setItem("frillSSO", msg_data["frillSSO"]);
            } catch (error) {
              console.log("no localstorage found", error);
            }
            set_linked_handles(msg_data["handle_data"]);
            try {
              localStorage.setItem("user_has_posts", msg_data["user_has_posts"]);
            } catch (error) {}
            let redirectTO = !msg_data["user_has_posts"]
              ? "/onboarding/"
              : // : msg_data["active_user"]
                // ? "/dashboard"  // no dashboard for semrush
                getPath("/content_library"); //redirect to new onboarding
            resolve(redirectTO);
          } else {
            window.location.href = "https://www.semrush.com/apps/predis-ai/";
          }
        } else {
          reject(
            new Error(
              "Invalid login request. Please try reloading the page or contact support if the problem persists."
            )
          );
        }
      })
      .catch((err) => reject(err));
  });
};

export const logout_facebook = async () => {
  // no longer needed since the login is driven via oauth2 so window.FB object is of no use
  try {
    window.FB.getLoginStatus(function (response) {
      if (response["status"] == "connected") {
        window.FB.logout(function (response) {
          return new Promise((resolve) => resolve(true));
        });
      } else {
        return new Promise((resolve) => resolve(true));
      }
    });
  } catch (error) {
    return new Promise((resolve) => resolve(true));
  }
};

function remove_account_details() {
  var req_keys = [
    "tour_1_current_step",
    "tour_1_end",
    "tour_2_current_step",
    "tour_2_end",
    "acquisition_response"
  ];
  var req_vals = [];
  req_keys.forEach(function (i, k) {
    req_vals.push(localStorageSafe("getItem", i));
  });
  localStorageSafe("clear");
  req_keys.forEach(function (i, k) {
    localStorageSafe("setItem", i, req_vals[k]);
  });
}
export const logoutFn = async () => {
  // var login_platform = localStorage.getItem("login_platform");
  const { loginPlatform } = mainStore.getState().userData;
  const appId = localStorageSafe("getItem", "appId");
  try {
    remove_account_details();
  } catch (error) {
    console.log("no localstorage found", error);
  }
  if (loginPlatform == "facebook") {
    // logging out FB ig login platform was facebook
    await logout_facebook();
  }
  localStorageSafe("clear");
  // not sure why setting appId is required after clearing in logout
  // localStorageSafe("setItem", "appId", appId);
  // window.location = "./";
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "logout" }));
  return;
};

const getEcomMetaData = () => {
  const is_mobile = mainStore.getState().uiStates.isWebView;
  const user_timezone = mainStore.getState().globalReducer.userTimezone;
  const device = check_device();
  const referral = getCookie("pred_ref");
  const referral_session_history = getCookie(REF_HISTORY_COOKIE_KEY);
  const http_origin = window.location.origin;
  return {
    is_mobile,
    user_timezone,
    device,
    referral,
    referral_session_history,
    http_origin
  };
};

const storeEcomMetaData = (res, platform) => {
  let msg_data = res.data;
  store_user_details(
    msg_data["username"],
    msg_data["useremail"],
    msg_data["user_img_url"],
    // msg_data["system_uid"],
    msg_data,
    platform
  );
  set_linked_handles(msg_data["handle_data"]);
  try {
    localStorage.setItem(`ecom_signup`, msg_data[`first_${platform}_login`]);
    localStorage.setItem(`need_download_ecom_products`, msg_data[`first_${platform}_login`]);
    localStorage.setItem("ws_limit_available", msg_data["ws_limit_available"]);
    localStorage.setItem("config_found", msg_data["config_found"]);
    localStorage.setItem("frillSSO", msg_data["frillSSO"]);
    localStorage.setItem("nOnce", msg_data["nonce"]);
  } catch (error) {
    console.log("No localstorage found", error);
  }

  msg_data[`first_${platform}_login`] && window.fbq("track", "CompleteRegistration");

  mainStore.dispatch({
    type: "ecomStates/setEcomSignup",
    payload: msg_data[`first_${platform}_login`]
  });
  mainStore.dispatch({
    type: "ecomStates/setNeedEcomDownload",
    payload: msg_data[`first_${platform}_login`]
  });
  mainStore.dispatch({
    type: "ecomStates/setEcomWsLimitAvail",
    payload: msg_data["ws_limit_available"]
  });

  mainStore.dispatch({
    type: "ecomStates/setNOnce",
    payload: msg_data["nonce"]
  });

  let redirectTo = msg_data["user_has_posts"]
    ? getPath("/content_library")
    : `/onboarding/?is_ecom=true&platform=${platform}`;
  // : getPath(`/onboarding-ecom/${platform}`);

  return redirectTo;
};

export const shopifyLoginFn = (qsData, app_id) => {
  const refCookie = getCookie("pred_ref");
  const urlCookie = getCookie("pref_ref_location");
  const historyCookie = getCookie(REF_HISTORY_COOKIE_KEY);
  let params = {
    user_timezone: mainStore.getState().globalReducer.userTimezone,
    device: check_device(),
    app_id: app_id,
    qs: qsData ? qsData : window.location.search.replace("?", "").trim(),
    referral: refCookie,
    referral_url: urlCookie,
    is_mobile: mainStore.getState().uiStates.isWebView,
    referral_session_history: historyCookie
  };
  let url_params = new URLSearchParams(params);
  mainStore.dispatch({
    type: "uiStates/showAlert",
    alertType: "info",
    payload: "Logging in with Shopify..."
  });
  return new Promise((resolve, reject) => {
    axios
      .get(`/users/login_shopify/?${url_params}`)
      .then((res) => {
        let redirectTo = storeEcomMetaData(res, "shopify");
        let msg_data = res.data;

        if (msg_data["prompt_brand_selection"]) {
          redirectTo = getPath("/ecom_ask/shopify");
        }

        resolve(redirectTo);
      })
      .catch((err) => reject(err));
  });
};

export const loginWix = (params) => {
  return new Promise((resolve, reject) => {
    let sendData = new URLSearchParams({
      ...params,
      ...getEcomMetaData()
    });

    axios
      .get(`/ecom_integrations/login_wix/?${sendData}`)
      .then((res) => {
        let redirectTo = storeEcomMetaData(res, "wix");
        let msg_data = res.data;

        if (msg_data["prompt_brand_selection"]) {
          redirectTo = getPath("/ecom_ask/wix");
        }
        resolve(redirectTo);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginSquarespace = (code, state) => {
  return new Promise((resolve, reject) => {
    let sendData = new URLSearchParams({
      code,
      state,
      ...getEcomMetaData()
    });

    axios
      .get(`/ecom_integrations/login_squarespace/?${sendData}`)
      .then((res) => {
        if (res.data?.redirect_uri) {
          window.location.href = res.data.redirect_uri;
        } else {
          const redirectTo = storeEcomMetaData(res, "squarespace");
          resolve(redirectTo);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginWoocommerce = (user_id) => {
  const refCookie = getCookie("pred_ref");
  const urlCookie = getCookie("pref_ref_location");
  const historyCookie = getCookie(REF_HISTORY_COOKIE_KEY);
  return new Promise((resolve, reject) => {
    let sendData = new URLSearchParams({
      user_id,
      referral: refCookie,
      referral_url: urlCookie,
      is_mobile: mainStore.getState().uiStates.isWebView,
      referral_session_history: historyCookie,
      ...getEcomMetaData()
    });

    axios
      .get(`/ecom_integrations/login_woocommerce/?${sendData}`)
      .then((res) => {
        const redirectTo = storeEcomMetaData(res, "woocommerce");
        resolve(redirectTo);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginEcwid = (code, state) => {
  return new Promise((resolve, reject) => {
    let sendData = new URLSearchParams({
      code,
      state,
      ...getEcomMetaData()
    });

    axios
      .get(`/ecom_integrations/login_ecwid/?${sendData}`)
      .then((res) => {
        if (res.data?.redirect_uri) {
          window.location.href = res.data.redirect_uri;
        } else {
          const redirectTo = storeEcomMetaData(res, "ecwid");
          resolve(redirectTo);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
