import SvgIcon from "../../../designSystem/SvgIcon";

const SpainFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='513'
        height='343'
        viewBox='0 0 513 343'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_301_1272)'>
          <path d='M0 1H512V343H0V1Z' fill='white' />
          <path d='M0 1H512V92.2H0V1ZM0 251.8H512V343H0V251.8Z' fill='#D03433' />
          <path d='M0 92H512V252H0V92Z' fill='#FBCA46' />
          <path d='M177.493 160.6H200.249V172H177.493V160.6Z' fill='white' />
          <path
            d='M163.84 194.8C163.84 201.64 170.667 206.2 177.493 206.2C184.32 206.2 191.147 201.64 191.147 194.8L193.422 160.6H161.564L163.84 194.8ZM150.187 160.6C150.187 153.76 154.738 149.2 159.289 149.2H193.422C200.249 149.2 204.8 153.76 204.8 158.32V160.6L202.524 194.8C200.249 208.48 191.147 217.6 177.493 217.6C163.84 217.6 154.738 208.48 152.462 194.8L150.187 160.6Z'
            fill='#A41517'
          />
          <path
            d='M154.738 172H200.249V183.4H188.871L177.493 206.2L166.116 183.4H154.738V172ZM120.604 137.8H143.36V217.6H120.604V137.8ZM211.627 137.8H234.382V217.6H211.627V137.8ZM154.738 126.4C154.738 119.56 159.289 115 166.116 115H188.871C195.698 115 200.249 119.56 200.249 126.4V130.96C200.249 135.52 197.973 137.8 193.422 137.8H159.289C157.013 137.8 154.738 135.52 154.738 133.24V126.4Z'
            fill='#A41517'
          />
        </g>
        <defs>
          <clipPath id='clip0_301_1272'>
            <rect width='513' height='342' fill='white' transform='translate(0 0.759766)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default SpainFlagIcon;
