import { styled } from "@mui/material/styles";
import MuiSvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface CustomSvgIconProps extends SvgIconProps {
  // Add any additional props if needed
}

const SvgIcon = styled(MuiSvgIcon)<CustomSvgIconProps>(({ theme }) => ({}));

export default SvgIcon;
