import { styled } from "@mui/material/styles";
import MuiCssBaseline, { CssBaselineProps } from "@mui/material/CssBaseline";

interface CustomCssBaselineProps extends CssBaselineProps {
  // Add any additional props if needed
}

const CssBaseline = styled(MuiCssBaseline)<CustomCssBaselineProps>(({ theme }) => ({}));

export default CssBaseline;
