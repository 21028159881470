import { styled } from "@mui/material/styles";
import MuiBox, { BoxProps } from "@mui/material/Box";

interface CustomBoxProps extends BoxProps {
  // Add any additional props if needed
}

const Box = styled(MuiBox)<CustomBoxProps>(({ theme }) => ({}));

export default Box;
