import { styled } from "@mui/material/styles";
import MuiTypography, { TypographyProps } from "@mui/material/Typography";

interface CustomTypographyProps extends TypographyProps {
  // Add any additional props if needed
}

const Typography = styled(MuiTypography)<CustomTypographyProps>(({ theme }) => ({}));

export default Typography;
