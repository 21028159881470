import { combineReducers, configureStore } from "@reduxjs/toolkit";
import calenderStates from "../components/ContentCalendar/features/calenderStates";
import uiStates from "../components/common/Features/uiStates";
import userData from "../components/common/Features/userData";

import getPostTagsSlice from "../components/ContentLibrary/Features/getPostTagsSlice";
import getPostsSlice from "../components/ContentLibrary/Features/getPostsSlice";
import ecomStateSlice from "../components/ContentLibrary/Features/ecomStateSlice";
import newPostConfigSlice from "../components/CreateNewPost/features/newPostConfigSlice";
import customPdfStates from "../components/CustomPdf/features/customPdfStates";
import chatSlice from "../components/IdeaLab/Assistant/chatSlice";
import newPostStatesSlice from "../components/NewPost_depr/Features/newPostStatesSlice";
import onboardingStatesSlice from "../components/Onboarding2/features/onboardingStatesSlice";
import polotnoStatesSlice from "../components/Polotno/features/polotnoStatesSlice";
import previewPostSlice from "../components/PreviewPost/New Preview/features/previewPostSlice";
import settingStates from "../components/Settings/features/settingStates";
import sharingStates from "../components/SharePreview/feature/sharingStates";
import stepComponentSlice from "../components/StepsComponent/features/stepComponentSlice";
import workspacesStates from "../components/Workspace/features/workspacesStates";
import { reducer } from "./reducers";

// import { createLogger } from 'redux-logger';

const combinedReducer = combineReducers({
  globalReducer: reducer,
  customPdfStates,
  sharingStates,
  getPosts: getPostsSlice,
  getPostTags: getPostTagsSlice,
  newPostConfig: newPostConfigSlice,
  userData: userData,
  uiStates: uiStates,
  ecomStates: ecomStateSlice,
  polotnoStates: polotnoStatesSlice,
  onboarding: onboardingStatesSlice,
  newPostStates: newPostStatesSlice,
  previewPostSlice,
  stepComponentSlice,
  workspacesStates,
  settingStates,
  calenderStates,
  chatSlice
});

const rootReducer = (state, action) => {
  if (action.type === "logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};
// const logger = createLogger();

const mainStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return getDefaultMiddleware(); //.concat([logger]);
    } else {
      return getDefaultMiddleware(); //.concat([logger]);
    }
  }
});

export default mainStore;
