import { styled } from "@mui/material/styles";
import MuiCircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";

interface CustomCircularProgressProps extends CircularProgressProps {
  // Add any additional props if needed
}

const CircularProgress = styled(MuiCircularProgress)<CustomCircularProgressProps>(
  ({ theme }) => ({})
);

export default CircularProgress;
