import { styled } from "@mui/material/styles";
import MuiGrid, { GridProps } from "@mui/material/Grid";

interface CustomGridProps extends GridProps {
  // Add any additional props if needed
}

const Grid = styled(MuiGrid)<CustomGridProps>(({ theme }) => ({}));

export default Grid;
