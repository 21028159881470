import Box from "./designSystem/Box";
import CircularProgress from "./designSystem/CircularProgress";
import { Suspense } from "react";

export const LazyLoader = ({ children, fallback = null }) => {
  return (
    <Suspense
      fallback={
        fallback ? (
          fallback
        ) : (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center"
            }}
          >
            <Box sx={{ width: "300px" }}>
              <CircularProgress />
            </Box>
            Loading...
          </Box>
        )
      }
    >
      {children}
    </Suspense>
  );
};
