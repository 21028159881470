import SvgIcon from "../../../designSystem/SvgIcon";

const DenmarkFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='513'
        height='343'
        viewBox='0 0 513 343'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_301_1357)'>
          <path d='M0 0.891113H513V342.895H0V0.891113Z' fill='#D80027' />
          <path d='M0 0.891113H513V114.888H0V0.891113Z' fill='black' />
          <path d='M0 228.887H513V342.884H0V228.887Z' fill='#FFDA44' />
        </g>
        <defs>
          <clipPath id='clip0_301_1357'>
            <rect width='513' height='342' fill='white' transform='translate(0 0.893066)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default DenmarkFlagIcon;
