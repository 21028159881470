import SvgIcon from "../../../designSystem/SvgIcon";

const NetherlandFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='513'
        height='342'
        viewBox='0 0 513 342'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_301_605)'>
          <path d='M0 113.994H513V227.994H0V113.994Z' fill='white' />
          <path d='M0 -0.00585938H513V113.994H0V-0.00585938Z' fill='#CD1F2A' />
          <path d='M0 227.994H513V341.994H0V227.994Z' fill='#1D4185' />
        </g>
        <defs>
          <clipPath id='clip0_301_605'>
            <rect width='513' height='342' fill='white' transform='translate(0 -0.00585938)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default NetherlandFlagIcon;
